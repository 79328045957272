import React from 'react'
import { navigate } from 'gatsby';

import AuthUserContext from 'components/firebase/AuthUserContext';
import isBrowser from "utils/isBrowser"
import ROUTES from 'utils/routes'

//routes that only a logged in user can access
export default function LoggedInRoute({ component: Component, ...rest}) {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <Component {...rest} /> : (
          isBrowser ? navigate(ROUTES.SIGN_IN, {state: rest.location}) : null
        )
      }
    </AuthUserContext.Consumer>
  );
}
