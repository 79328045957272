import "./Account.scss";
import React, { useState } from 'react';

import { useFirebase } from 'components/firebase/Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

const PasswordField = () => {
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [update, setUpdate] = useState(false)

  const firebase = useFirebase()

  const invalid = newPassword!==confirmPassword || newPassword===''; //the passwords do not match OR the password is empty

  const onSubmit = e => {
    e.preventDefault();

    if(!invalid) {
      firebase
      .doPasswordUpdate(newPassword)
      .then(() => {
        setConfirmPassword("")
        setError(null)
        setNewPassword("")
        setUpdate(false)
      })
      .catch(setError);
    }
  }

  return (
    <form className="field" onSubmit={onSubmit}>
    <div className="box" id={update ? 'invisible' : 'enterLeft'}>
      <div>
        <h3>Password:</h3>
        <div className="currentValue">**********</div>
      </div>
      <span><FontAwesomeIcon className="fa-3x" icon={faCaretRight} color="#2BA29A" onClick={() => setUpdate(!update)}/></span>
    </div>

    <div className="testbox" id={update ? 'enterLeft' : 'invisible'}>
    <div>
        <label>New Password:</label>
        <input
          name="newPassword"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          type="password"
        />
      </div>
      <div>
        <label>Confirm Password:</label>
        <input
          name="confirmPassword"
          id={newPassword===confirmPassword ? 'validField' : 'invalidField'}
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)} type="password"
        />
      </div>

      <span>
        <button disabled={invalid} id="teal-sm" type="submit">Save</button>
      </span>
    </div>

    {error && <p>{error.message}</p>}

  </form>
  );
}

export default PasswordField;
